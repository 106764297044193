import './App.css';
import Hero from './components/hero/Hero';
import Program from './components/program/Program';
import Reasons from './components/Reasons/Reasons';
import Plans from './components/plans/Plans';
import Testimonials from './components/Testimonials/Testimonials';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
          

    </div>
  );
}

export default App;
